import React, { Suspense, lazy } from 'react';
import { HashRouter, Navigate, Route } from 'react-router-dom';
//import PrivateRoutes from './private.routes';
import {Usuarios, CreateUsuario, UpdateUsuario} from '../pages/protected/Usuarios';
import { useAuthContext } from '../contexts/auth.context';
import NotFoundRoute from './not-found.route';
import { Loader } from '../components';
import { HistoriaContextProvider } from '../contexts';
import { RemitoContextProvider } from '../contexts/remito.context';
import { FacturaContextProvider } from '../contexts/factura.context';

const Login = lazy(() => import('../pages/auth/login'));
const ForgotPassword = lazy(() => import('../pages/auth/forgot.password'));
const RecoverPassword = lazy(() => import('../pages/auth/recover.password'));
const PrivateRoutes = lazy(() => import('./private.routes'));
const Dashboard = lazy(() => import('../pages/protected/Dashboard/Dashboard'));
const Agenda = lazy(() => import('../pages/protected/Agenda/Agenda'));
const Perfil = lazy(() => import('../pages/protected/Perfil/perfil'));
const Profesionales = lazy(() => import('../pages/protected/Profesionales/Profesionales'))
const ProfesionalCreate = lazy(() => import('../pages/protected/Profesionales/ProfesionalCreate'))
const HistoriaClinica = lazy(() => import('../pages/protected/HistoriaClinica/HistoriaClinica'))
const GruposArticulos = lazy(() => import('../pages/protected/GrupoArticulo/GruposArticulos'))
const GrupoArticuloCreate = lazy(() => import('../pages/protected/GrupoArticulo/GrupoArticuloCreate'))
const GrupoArticuloEdit = lazy(() => import('../pages/protected/GrupoArticulo/GrupoArticuloEdit'))
const Pacientes = lazy(() => import('../pages/protected/Pacientes/Pacientes'))
const PacienteCreate = lazy(() => import('../pages/protected/Pacientes/PacienteCreate'))
const Almacenes = lazy(() => import('../pages/protected/Almacen/Almacenes'))
const AlmacenCreate = lazy(() => import('../pages/protected/Almacen/AlmacenCreate'))
const AlmacenEdit = lazy(() => import('../pages/protected/Almacen/AlmacenEdit'))
const Articulos = lazy(() => import('../pages/protected/Articulo/Articulos'))
const ArticuloCreate = lazy(() => import('../pages/protected/Articulo/ArticuloCreate'))
const CategoriasPacientes = lazy(() => import('../pages/protected/CategoriaPaciente/CategoriasPacientes'))
const CreateCategoriaPaciente = lazy(() => import('../pages/protected/CategoriaPaciente/CategoriaPacienteCreate'))
const GestionTurno = lazy(() => import('../pages/protected/GestionTurno/GestionTurno'))
const AtencionTurno = lazy(() => import('../pages/protected/AtencionTurno/AtencionTurno'))
const Remitos = lazy(() => import('../pages/protected/Remitos/Remitos'))
const Stock = lazy(() => import('../pages/protected/Stock/Stock'))
const RecepcionMateriales = lazy(() => import('../pages/protected/Stock/RecepcionMateriales/RecepcionMateriales'))
const Facturas = lazy(() => import('../pages/protected/Facturas/Facturas'))
const FacturaDetalle = lazy(() => import('../pages/protected/Facturas/components/FacturaDetalle'))
const Precios = lazy(() => import('../pages/protected/Precios/Precios'))
const Pagos = lazy(() => import('../pages/protected/Pagos/Pagos'))
const PagoCreate = lazy(() => import('../pages/protected/Pagos/PagoCreate'))
const CuentaCorriente = lazy(() => import('../pages/protected/CuentaCorriente/CuentaCorriente'))
const Caja = lazy(() => import('../pages/protected/Caja/Caja'));
const Comision = lazy(() => import('../pages/protected/Comision/Comision'));

const MainRouter = () => {

  const { user, isAdmin, isProfesional, isSecretaria} = useAuthContext()
  return (
    <Suspense fallback={<Loader />} >
      <HashRouter>

      
        <NotFoundRoute>

          <Route index element={<Navigate to='/agenda' />} />
          <Route path="/login" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/recover-password" element={<RecoverPassword />} />

          <Route element={<PrivateRoutes isAllowed={!!user} />}>
            <Route path='/agenda' element={<Agenda />} />
            <Route path='/perfil' element={<Perfil />} />
            <Route path='/perfil/:userId' element={<Perfil />} />
            <Route path='/remitos' element={<RemitoContextProvider><Remitos /></RemitoContextProvider>} />
            <Route path='/facturas' element={<FacturaContextProvider><Facturas /></FacturaContextProvider>} />
            <Route path='/facturas-vista/:id' element={<FacturaContextProvider><FacturaDetalle /></FacturaContextProvider>} />
            <Route path='/pagos-recibidos' element={<RemitoContextProvider><Pagos /></RemitoContextProvider>} />
            <Route path='/pagos-recibidos/create' element={<RemitoContextProvider><PagoCreate /></RemitoContextProvider>} />
            <Route path='/pagos-recibidos/update/:id' element={<RemitoContextProvider><PagoCreate /></RemitoContextProvider>} />
            <Route path='/cuenta-corriente' element={<CuentaCorriente />} />
          </Route>

          <Route element={<PrivateRoutes isAllowed={!!user && (isAdmin || isProfesional || isSecretaria)} redirectTo='/agenda' />}>
            <Route path="/pacientes" element={<Pacientes />} />
            <Route path="/pacientes/create" element={<PacienteCreate />} />
            <Route path="/pacientes/update/:id" element={<PacienteCreate />} />
            <Route path="/profesionales" element={<Profesionales />} />
            <Route path="/profesionales/create" element={<ProfesionalCreate />} />
            <Route path="/profesionales/update/:id" element={<ProfesionalCreate />} />
            <Route path="/grupo-articulos" element={<GruposArticulos />} />
            <Route path="/grupo-articulos/create" element={<GrupoArticuloCreate />} />
            <Route path="/grupo-articulos/update/:id" element={<GrupoArticuloEdit />} />
            <Route path="/gestion-turnos" element={<GestionTurno />} />
            <Route path="/recepcion-materiales" element={<RecepcionMateriales />} />
            <Route path="/stocks" element={<Stock />} />
            <Route path="/historia/:id" element={<HistoriaContextProvider><HistoriaClinica /></HistoriaContextProvider>} />
          </Route>

          <Route element={<PrivateRoutes isAllowed={!!user && (isAdmin || isProfesional)} redirectTo='/agenda' />}>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/articulos" element={<Articulos />} />
            <Route path="/articulos/create" element={<ArticuloCreate />} />
            <Route path="/articulos/update/:id" element={<ArticuloCreate />} />
            <Route path="/atencion-turnos" element={<AtencionTurno />} />
            <Route path='/cierre-caja' element={<Caja />} />
          </Route>

          <Route element={<PrivateRoutes isAllowed={!!user && isAdmin} redirectTo='/agenda' />}>
            <Route path="/usuarios" element={<Usuarios />} />
            <Route path="/usuarios/create" element={<CreateUsuario />} />
            <Route path="/usuarios/update/:id" element={<UpdateUsuario />} />
            <Route path="/almacenes" element={<Almacenes />} />
            <Route path="/almacenes/create" element={<AlmacenCreate />} />
            <Route path="/almacenes/update/:id" element={<AlmacenEdit />} />
            <Route path="/categoria-pacientes" element={<CategoriasPacientes />} />
            <Route path="/categoria-pacientes/create" element={<CreateCategoriaPaciente />} />
            <Route path="/categoria-pacientes/update/:id" element={<CreateCategoriaPaciente />} />
            <Route path="/precios" element={<Precios />} />
            <Route path='/comisiones' element={<Comision />} />
          </Route>
          
        </NotFoundRoute>
      
      </HashRouter>
    </Suspense>
  )
}

export default MainRouter