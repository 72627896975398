import { createContext, useMemo, useState, useContext, useEffect } from "react";
import { getAll, getModel } from "../services";
import { useNavigate } from "react-router-dom";

export const DataContext = createContext();

export function DataContextProvider({children}){
    const [roles, setRoles] = useState([]);
    const [pacientes, setPacientes] = useState([]);
    const [profesionales, setProfesionales] = useState([]);
    const [turnos, setTurnos] = useState([]);
    const [stock, setStock] = useState([]);
    const [dollarRate, setDollarRate] = useState(0);

    const navigate = useNavigate();

    useEffect(() => {
        getData();
    }, [])

    const getData = () => {
        getRoles();
        getPacientes();
        getTurnos();
        getProfesionales();
        getStock();
        getDollarRate();
    }

    const getRoles = async () => {
        const roles = await getAll('roles');
        if(roles.status === 200){
            setRoles(roles.data);
        } else if(roles.status === 401){
            navigate('/login');
        }
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const getPacientes = async () => {
        const pacientes = await getAll('pacientes');
        if(pacientes.status === 200){
            setPacientes(pacientes.data);
        } else if(pacientes.status === 401){
            navigate('/login');
        }
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const getProfesionales = async () => {
        const profesionales = await getAll('profesionales');
        if(profesionales.status === 200){
            setProfesionales(profesionales.data);
        } else if(profesionales.status === 401){
            navigate('/login');
        }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const getTurnos = async (search = '') => {
        const turnos = await getAll('turnos', search);
        if(turnos.status === 200){
            setTurnos(turnos.data);
        } else if(turnos.status === 401){
            navigate('/login');
        }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const getStock = async (search = '') => {
        const newStock = await getAll('stocks', search);
        if(newStock.status === 200){
            setStock(newStock.data);
        } else if(newStock.status === 401){
            navigate('/login');
        }
    }

    const getDollarRate = async () => {
        try {
            const respDolarLocal = await getModel('tipos-cambio/last');
            const dolar = parseFloat(respDolarLocal.data[0].valor);
            setDollarRate(dolar)
            window.localStorage.setItem('dollarRate', dolar);
        } catch (error) {
            console.error('Error al obtener tipo de cambio en local');
        }
    }


    const value = useMemo(()=>({
        roles, pacientes, getPacientes, setPacientes, turnos, setTurnos, getTurnos, 
        profesionales, setProfesionales, getProfesionales, stock, setStock, getStock, dollarRate
    }), [
        roles, pacientes, getPacientes, setPacientes, turnos, setTurnos, getTurnos, 
        profesionales, setProfesionales, getProfesionales, stock, setStock, getStock, dollarRate
    ]);

    
    return <DataContext.Provider value={value}>{children}</DataContext.Provider>

}

export const useDataContext = () => useContext(DataContext)