import { createContext, useMemo, useState, useContext } from "react";
import { getAll, getModelById } from "../services";

export const FacturaContext = createContext();

export function FacturaContextProvider({children}){

  const [facturas, setFacturas] = useState([]);
  const [factura, setFactura] = useState(null);
  
  const [profesional, setProfesional] = useState(null);
  const [productos, setProductos] = useState([]);
  const [practicas, setPracticas] = useState([]);
  
  const getFacturas = async () => {
    const resp = await getAll('facturas');
    if(resp.status === 200){
      setFacturas(resp.data);
    }
  }
  
  const getFacturaById = async (id) => {
    const resp = await getModelById('facturas/id', id);
    if(resp.status === 200){
      setFactura(resp.data);
    }
  }

  const value = useMemo(()=>({
      profesional, setProfesional, productos, setProductos, 
      practicas, setPracticas, facturas, setFacturas, getFacturas,factura, setFactura, getFacturaById
  }), 
  [profesional, setProfesional, productos, setProductos, 
    practicas, setPracticas, facturas, setFacturas, getFacturas,factura, setFactura, getFacturaById
  ]);

  
  return <FacturaContext.Provider value={value}>{children}</FacturaContext.Provider>

}

export const useFacturaContext = () => useContext(FacturaContext)