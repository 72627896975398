/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useMemo, useState, useContext } from "react";
import { createModel, getAll } from "../services";
import { useAuthContext } from "./auth.context";
import { useNavigate } from "react-router-dom";

export const RemitoContext = createContext();

export function RemitoContextProvider({children}){
  const { user } = useAuthContext();
  const navigate = useNavigate();
    const initialRemito = {
      visita: '',
      paciente: '',
      fecha: new Date().toISOString(),
      profesional: '',
    articulos: [],
    practicas: [],
    estado: 'pendiente',
    fechaCambioEstado: new Date().toISOString(),
    usuarioCambioEstado: ''
  }

  const [remitos, setRemitos] = useState([]);
  
  const [profesional, setProfesional] = useState(null);
  const [productos, setProductos] = useState([]);
  const [practicas, setPracticas] = useState([]);

  const [isVisitaEditing, setIsVisitaEditing] = useState(true);
  
  const getRemitos = async () => {
    const resp = await getAll('remitos');
    if(resp.status === 200){
      setRemitos(resp.data);
    }
  }

  const onRemitoToFactura = async (remito) => {
    /**
     * Numero del punto de venta
     **/
    const punto_de_venta = 1;

    /**
     * Tipo de factura
     * a responsable inscripto => factura A (id=1)
     * a consumidor final, monotributista o excento => factura B (id=6)
     **/

    const tipo_de_factura = !remito.paciente.condicionIva || remito.paciente.condicionIva !== 'IVA Responsable Inscripto' ? 6 : 1 ; // 1 = Factura A
    

    /**
     * Concepto de la factura
     *
     * Opciones:
     *
     * 1 = Productos 
     * 2 = Servicios 
     * 3 = Productos y Servicios
     **/
    const concepto = 2;

    /**
     * Importe sujeto al IVA (sin icluir IVA)
     **/
    const importe_gravado = (parseFloat(remito.montoTotal) / 1.21).toFixed(2)
    ;

    /**
     * Importe exento al IVA
     **/
    const importe_exento_iva = 0;

    /**
     * Importe de IVA
     **/
    const importe_iva = (parseFloat(importe_gravado) * 0.21).toFixed(2);
    const importe_total = (parseFloat(importe_gravado) + parseFloat(importe_iva) + parseFloat(importe_exento_iva))
    console.log('MONTO', parseFloat(importe_gravado).toFixed(2));

    
    /**
     * Tipo de documento del comprador
     *
     * Opciones:
     *
     * 80 = CUIT 
     * 86 = CUIL 
     * 96 = DNI
     * 99 = Consumidor Final 
     **/
    let tipo_de_documento = !remito.paciente.condicionIva || remito.paciente.condicionIva === 'Consumidor Final' 
      ? 99 
      : remito.paciente.condicionIva === 'IVA Responsable Inscripto' ? 80 : 96;

    if(tipo_de_factura === 6 && importe_total >= 92720){
      tipo_de_documento = 96;
    }
    /**
     * Numero de documento del comprador (0 para consumidor final)
     **/

    let numero_de_documento = '';
    switch (tipo_de_documento) {
      case 80:
        numero_de_documento = remito.paciente.cuit
        break;
      case 86:
        numero_de_documento = remito.paciente.cuit
        break;
      case 96:
        numero_de_documento = remito.paciente.dni
        break;
      case 99:
        numero_de_documento = 0
        break;
    
      default:
        break;
    }

    const data = {
      CantReg 	: 1, // Cantidad de facturas a registrar
      PtoVta 	: punto_de_venta,
      CbteTipo 	: tipo_de_factura, 
      Concepto 	: concepto,
      DocTipo 	: tipo_de_documento,
      DocNro 	: numero_de_documento,
      ImpTotal 	: parseFloat(importe_total).toFixed(2),
      ImpTotConc: 0, // Importe neto no gravado
      ImpNeto 	: importe_gravado,
      ImpOpEx 	: importe_exento_iva,
      ImpIVA 	: importe_iva,
      ImpTrib 	: 0, //Importe total de tributos
      MonId 	: 'PES', //Tipo de moneda usada en la factura ('PES' = pesos argentinos) 
      MonCotiz 	: 1, // Cotización de la moneda usada (1 para pesos argentinos)  
      Iva 		:  // Alícuotas asociadas a la factura
        {
          Id 		: 5, // Id del tipo de IVA (5 = 21%)
          BaseImp 	: importe_gravado,
          Importe 	: importe_iva 
        },
      remito: remito._id,
      usuarioFactura: user.id
      
    };

    //ENVIAR A LA API PARA QUE SE GENERE LA FACTURA
    try {
      const respFactura = await createModel('facturas', data);
      if(respFactura.status === 200){
        alert('Factura generada ok');
        //navigate('/facturas-vista/' + respFactura.data._id)
        navigate('/facturas')

      } else {
        alert('No se pudo facturar');
      }
    } catch (error) {
      
    }
  }

  const value = useMemo(()=>({
      initialRemito,profesional, setProfesional, productos, setProductos, 
      practicas, setPracticas, isVisitaEditing, setIsVisitaEditing, remitos, setRemitos, getRemitos, onRemitoToFactura
  }), 
  [initialRemito,profesional, setProfesional, productos, setProductos, 
    practicas, setPracticas, isVisitaEditing, setIsVisitaEditing, remitos, setRemitos, getRemitos, onRemitoToFactura
  ]);

  
  return <RemitoContext.Provider value={value}>{children}</RemitoContext.Provider>

}

export const useRemitoContext = () => useContext(RemitoContext)