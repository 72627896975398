import { createContext, useMemo, useState, useContext } from "react";

export const HistoriaContext = createContext();

export function HistoriaContextProvider({children}){
    const initialVisita = {
    paciente: '',
    fecha: new Date().toISOString(),
    profesional: '',
    comentarios: '',
    articulos: [],
    practicas: [],
    imagenes: [],
    estado: 'borrador',
    fechaCambioEstado: new Date().toISOString(),
    montoTotal: 0
  }

    const [newVisita, setNewVisita] = useState(initialVisita);
    const [profesional, setProfesional] = useState(null);
    const [productos, setProductos] = useState([]);
    const [practicas, setPracticas] = useState([]);
    const [comentarios, setComentarios] = useState(initialVisita.comentarios);
    const [imagenes, setImagenes] = useState(initialVisita.imagenes);
    const [isVisitaEditing, setIsVisitaEditing] = useState(true);
    const [imagenesToDelete, setImagenesToDelete] = useState([])
    
    const value = useMemo(()=>({
        initialVisita, newVisita, setNewVisita,profesional, setProfesional, productos, setProductos, practicas, setPracticas,
         
        comentarios, setComentarios, imagenes, setImagenes, isVisitaEditing, setIsVisitaEditing, imagenesToDelete, setImagenesToDelete
    }), 
    [initialVisita, newVisita, setNewVisita,profesional, setProfesional, productos, setProductos, practicas, setPracticas,
      
      comentarios, setComentarios, imagenes, setImagenes, isVisitaEditing, setIsVisitaEditing, imagenesToDelete, setImagenesToDelete
    ]);

    
    return <HistoriaContext.Provider value={value}>{children}</HistoriaContext.Provider>

}

export const useHistoriaContext = () => useContext(HistoriaContext)